import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Skeleton, TableFooter, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { SALEORDER_CANCEL_RESET, SALEORDER_CREATE_RESET, SALEORDER_LIST_RESET, SALEORDER_READ_RESET, SALEORDER_RESET } from '../../../containers/SaleOrder/types';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import FormatUtil from '../../../utils/FormatUtil';
import { ContentCopy } from '@mui/icons-material';

const OrdersItemsPage = props => {
  const {
    token,
    history,
    isMobile,
    saleOrders,
    match,
    doGetSaleOrders,
    doCancelSaleOrder,
    saleOrderCancel,
    doResetSaleOrder,
    doUpdateSaleOrder,
    doResetCartUpdate,
    doReadCart,
    saleOrderUpdate,
    saleOrderRead,
    doReadSaleOrder,
    doResetCart,
    doUpdateCart,
    cart,
    cartUpdate,
    width,
    user,
    doDuplicateCart,
  } = props;

  const { page } = match?.params;

  const [cancelRequest, setCancelRequest] = useState(false);
  const [paynowRequest, setPaynowRequest] = useState(false);
  const [paynowStartRequest, setPaynowStartRequest] = useState(false);
  const [paynowRedirectRequest, setPaynowRedirectRequest] = useState(false);
  const [duplicateOrderRequest, setDuplicateOrderRequest] = useState(false);
  const [cartFetch, setCartFetch] = useState(false);

  useEffect(() => {
    if (!cart?.fetching && cart?.id && cartFetch) {
      setCartFetch(false);
      let id = cart?.id;
      doReadCart({ token, id });
    }
  }, [cart]);
  useEffect(() => {
    if ((token, duplicateOrderRequest && saleOrders)) {
      setDuplicateOrderRequest(false);
      let saleOrder = saleOrders?.data?.filter(item => item.id === parseInt(page));
      if (saleOrder && saleOrder.length) {
        let id = saleOrder[0]?.cart_id;
        doDuplicateCart({ token, id });
        setCartFetch(true);
      }
    }
  }, [saleOrders, token, duplicateOrderRequest]);

  useEffect(() => {
    if (!saleOrders?.fetching && !saleOrders?.data && !saleOrders?.errors) {
      // doResetSaleOrder();
      let query = '';
      let direction = '';
      let order = '';
      let limit = 10;
      let page = 1;
      doGetSaleOrders({ token, query, direction, order, limit, page });
    }
  }, [saleOrders, token]);

  useEffect(() => {
    if (saleOrderCancel && !saleOrderCancel?.fetching && saleOrderCancel?.data) {
      let canceledSalesOrderId = saleOrderCancel?.data?.id;
      if (saleOrders?.data?.filter(item => item.id === canceledSalesOrderId)) {
        history.push('/catalog/saleorders');
        doResetSaleOrder(SALEORDER_CREATE_RESET);
        doResetSaleOrder(SALEORDER_READ_RESET);
        doResetSaleOrder(SALEORDER_CANCEL_RESET);
        doResetSaleOrder(SALEORDER_LIST_RESET);
        doResetCart();
      }
    }
  }, [saleOrderCancel, doResetSaleOrder, saleOrders]);

  // useEffect(() => {
  //   if (!cartUpdate.fetching && cartUpdate.data && !cartUpdate.errors) {
  //     doResetCartUpdate({});
  //     let saleOrder = saleOrders?.data?.filter(item => item.id === parseInt(page));
  //     if (saleOrder && saleOrder.length) {
  //       let id = saleOrder[0]?.cart_id;
  //       doReadCart({ token, id });
  //       let saleId = saleOrder[0]?.id;
  //       doReadSaleOrder({ token, id: saleId });
  //       setPaynowRedirectRequest(true);
  //     }
  //   }
  // }, [cartUpdate]);

  useEffect(() => {
    if (!cartUpdate.fetching && cartUpdate.data && !cartUpdate.errors) {
      doResetCartUpdate({});
      let saleOrder = saleOrders?.data?.filter(item => item.id === parseInt(page));
      if (saleOrder && saleOrder.length) {
        let id = saleOrder[0]?.cart_id;
        doReadCart({ token, id });
        setTimeout(function() {
          let saleId = saleOrder[0]?.id;
          doReadSaleOrder({ token, id: saleId });
          setTimeout(function() {
            setPaynowStartRequest(false);
            doReadCart({ token, id });
            setPaynowRedirectRequest(true);
          }, 1000);
        }, 2000);
      }
    }
  }, [cartUpdate]);

  useEffect(() => {
    if (paynowRequest) {
      setPaynowRequest(false);
      setPaynowStartRequest(true);
      let saleOrder = saleOrders?.data?.filter(item => item.id === parseInt(page));
      if (saleOrder && saleOrder.length) {
        let id = saleOrder[0]?.cart_id;
        let payload = {
          checkout_override: true,
        };
        doUpdateCart({ token, id, payload });
        /*
        setTimeout(function() {
          doReadSaleOrder({ token, id });
        }, 3000);*/
      }
    }
  }, [token, paynowRequest, setPaynowStartRequest]);

  useEffect(() => {
    if (paynowRedirectRequest && !saleOrderRead?.fetching && saleOrderRead?.data && !cart?.fetching && cart?.data) {
      setPaynowRedirectRequest(false);
      history.push('/catalog/payment');
    }
  }, [token, paynowRedirectRequest, saleOrderRead, cart]);

  useEffect(() => {
    if (cancelRequest) {
      setCancelRequest(false);
      if (saleOrderCancel && !saleOrderCancel?.fetching && saleOrderCancel?.data) {
        let canceledSalesOrderId = parseInt(page);
        if (saleOrders?.data?.filter(item => item.id === canceledSalesOrderId)) {
          history.push('/catalog/saleorders');
          doResetSaleOrder(SALEORDER_CREATE_RESET);
          doResetSaleOrder(SALEORDER_READ_RESET);
          doResetSaleOrder(SALEORDER_CANCEL_RESET);
          doResetCart();
        }
      }
      let payload = {
        sale_order_id: parseInt(page),
      };

      doCancelSaleOrder({ token, payload });
    }
  }, [cancelRequest, saleOrders, token]);

  const printAddress = addressData => {
    let line1 = `${addressData?.company_name ? addressData?.company_name : ``}`;
    let line2 = `${addressData?.address_line_1 ? addressData?.address_line_1 : ``}`;
    let line3 = `${addressData?.address_line_2 ? addressData?.address_line_2 : ``}`;
    let line4 = `${addressData?.city ? addressData?.city : ``} ${addressData?.state ? addressData?.state : ``} ${addressData?.zipcode ? addressData?.zipcode : ``}`;
    return (
      <>
        <div>{line1}</div>
        <div>{line2}</div>
        {line3 ? <div>{line3}</div> : ``}
        <div>{line4}</div>
      </>
    );
  };

  const totalItems = cart => {
    return cart && cart?.cart_items ? cart?.cart_items.map(({ quantity }) => quantity).reduce((sum, i) => sum + i, 0) : 0;
  };

  const processSubTotal = (paymentType, total, user, saleOrderPayments) => {
    const totalAmount =
      saleOrderPayments &&
      saleOrderPayments.reduce((sum, item) => {
        // Check if item.amount exists before adding it to the sum
        if (item.amount) {
          return sum + parseFloat(item.amount);
        } else {
          return sum; // Ignore items without an amount property
        }
      }, 0);
    if (paymentType === 'card' && user?.customer && !user?.customer?.cc_exception) {
      return FormatUtil.numberWithCommas(total * user?.customer?.cc_rate + total - totalAmount);
    } else {
      return FormatUtil.numberWithCommas(parseFloat(total) - totalAmount);
    }
  };

  const getStatus = () => {
    let saleOrder = saleOrders?.data?.find(item => item.id === parseInt(page));
    let confirmed = saleOrder?.confirmed;
    let canceled = saleOrder?.canceled;
    let invoiceConfirmed = saleOrder?.invoice_confirmed;
    let registeredPayment = saleOrder?.payment_registered;
    if (canceled) {
      return <Chip size={`small`} label={`Canceled`} color="error" />;
    }
    if (!confirmed || !invoiceConfirmed || !registeredPayment) {
      return <Chip size={`small`} label={`Pending Payment`} color="error" />;
    }
    return <Chip size={`small`} label={`Confirmed`} color="success" />;
  };

  return (
    /* Page Container  */
    <Grid container>
      {/* Sub Header Container for Buttons with Navigation */}
      <Grid container pt={1} pb={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} style={{ textAlign: 'left', marginBottom: width < 600 ? 8 : 0 }}>
            <Tooltip title={'Back to Orders'}>
              <Button
                variant={'contained'}
                startIcon={<ArrowBackIosIcon />}
                onClick={() => {
                  history.push('/catalog/saleorders');
                }}
                size={'large'}
                fullWidth={width < 600}
              >
                <Typography sx={{ textTransform: 'none' }}>{isMobile ? 'Orders' : 'Back to Orders'}</Typography>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
            <Tooltip title={'Duplicate Order'}>
              <Button
                variant={'contained'}
                startIcon={<ContentCopy />}
                onClick={() => {
                  setDuplicateOrderRequest(true);
                }}
                size={'large'}
                fullWidth={width < 600}
              >
                <Typography sx={{ textTransform: 'none' }}>{isMobile ? 'Duplicate' : 'Duplicate order'}</Typography>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid item xs={width < 600 ? 12 : 4} md={12} lg={12}>
          {saleOrders?.data?.find(item => item.id === parseInt(page))?.canceled === 0 ? (
            (saleOrders?.data?.find(item => item.id === parseInt(page))?.confirmed === 0 && saleOrders?.data?.find(item => item.id === parseInt(page))?.canceled === 0) ||
            saleOrders?.data?.find(item => item.id === parseInt(page))?.invoice_confirmed === 0 ||
            saleOrders?.data?.find(item => item.id === parseInt(page))?.payment_registered === 0 ? (
              <div style={{ float: width < 600 ? 'none' : `right`, marginTop: width < 600 ? 10 : 10 }}>
                <Tooltip title={'Pay for sales order'}>
                  <LoadingButton
                    loading={cart?.fetching || saleOrderRead?.fetching || paynowRequest || paynowStartRequest}
                    loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                    disabled={cart?.fetching || saleOrderRead?.fetching || paynowRequest || paynowStartRequest}
                    onClick={() => {
                      setPaynowRequest(true);
                    }}
                    color={'primary'}
                    size={'large'}
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    fullWidth={false}
                    endIcon={<ReceiptIcon />}
                  >
                    <Typography sx={{ textTransform: 'none' }}>Pay Now</Typography>
                  </LoadingButton>
                </Tooltip>

                {saleOrders?.data?.find(item => item.id === parseInt(page))?.confirmed === 0 ? (
                  <Tooltip title={'Cancel order'}>
                    <LoadingButton
                      loading={saleOrderCancel?.fetching}
                      loadingIndicator={<CircularProgress style={{ color: `#fff` }} color="inherit" size={16} />}
                      disabled={saleOrderCancel?.fetching}
                      onClick={() => {
                        setCancelRequest(true);
                      }}
                      color={'error'}
                      size={'large'}
                      style={{ marginLeft: 10 }}
                      variant="contained"
                      fullWidth={false}
                      endIcon={<ReceiptIcon />}
                    >
                      <Typography sx={{ textTransform: 'none' }}>Cancel</Typography>
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ``
            )
          ) : (
            ''
          )}
        </Grid>
        {/*<Grid item xs={4} md={2} lg={2} p={isMobile ? 0.5 : 0}>*/}
        {/*  <Tooltip title={'Order Again'}>*/}
        {/*    <Button*/}
        {/*      variant={'contained'}*/}
        {/*      endIcon={<ShoppingBasketIcon />}*/}
        {/*      onClick={() => {*/}
        {/*        alert('You would have added this order to your basket if it existed yet.');*/}
        {/*      }}*/}
        {/*      size={'large'}*/}
        {/*    >*/}
        {/*      <Typography sx={{ textTransform: 'none' }}>{isMobile ? 'Reorder' : 'Order Again'}</Typography>*/}
        {/*    </Button>*/}
        {/*  </Tooltip>*/}
        {/*</Grid>*/}
      </Grid>

      {/* Order Information Container */}
      <Grid container pt={1} pb={1}>
        {/* Order Information Header */}
        <Grid item xs={12} align={isMobile ? 'center' : 'left'}>
          <Typography variant={'h4'} sx={{ fontWeight: 'bold' }}>
            Order
          </Typography>
        </Grid>

        {/* Mobile - Break to clean up the mobile UI a bit. */}
        {isMobile ? (
          <Grid item xs={12}>
            <hr />
          </Grid>
        ) : (
          ''
        )}

        {/* Order - Status Container */}
        <Grid container xs={12} md={6} lg={6} pt={1} pb={1}>
          {/* Order - Headings */}
          <Grid item xs={6} md={3}>
            <Stack align={'left'} spacing={0.5}>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>
                Status:
              </Typography>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>
                Ordered On:
              </Typography>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>
                Customer
              </Typography>
            </Stack>
          </Grid>

          {/* Order - Status Information */}
          <Grid item xs={6} md={9}>
            <Stack align={'left'} spacing={0.5}>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'normal' }}>
                {getStatus()}
              </Typography>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'normal' }}>
                {moment(saleOrders?.data?.find(item => item.id === parseInt(page))?.created_at).format('YYYY/MM/DD hh:mm a')}
              </Typography>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'normal' }}>
                {/* TODO: Implement actual data from API. */}
                {saleOrders?.data?.find(item => item.id === parseInt(page))?.contact_address?.company_name}
              </Typography>
            </Stack>
          </Grid>

          {/* Mobile - Break to clean up the mobile UI a bit. */}
          {isMobile ? (
            <Grid item xs={12}>
              <hr />
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        {/* Order - Delivery / Invoice Container */}
        <Grid container align={'center'}>
          {/* Delivery Information */}
          <Grid item xs={12} md={6} lg={6} p={0.25}>
            <Stack align={'left'} spacing={0.5}>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>
                Deliver To
              </Typography>
              {printAddress(saleOrders?.data?.find(item => item.id === parseInt(page))?.contact_address)}
            </Stack>
          </Grid>

          {/* Invoice Information */}
          <Grid item xs={12} md={6} lg={6} p={0.25}>
            <Stack align={'left'} spacing={0.5}>
              <Typography variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>
                Invoice To
              </Typography>
              {printAddress(saleOrders?.data?.find(item => item.id === parseInt(page))?.contact_address)}
            </Stack>
          </Grid>

          {/* Mobile - Break to clean up the mobile UI a bit. */}
          {isMobile ? (
            <Grid item xs={12}>
              <hr />
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        {/*/!* Order - Dispatched Container *!/*/}
        {/*<Grid container sm={12} md={12} lg={12}>*/}
        {/*  <Grid item xs={12} md={3}>*/}
        {/*    <Stack spacing={0.5}>*/}
        {/*      /!* TODO: Implement actual image from company cloud. *!/*/}
        {/*      <Grid align={'center'}>*/}
        {/*        <Skeleton variant="rectangular" width={150} height={150} />*/}
        {/*      </Grid>*/}
        {/*      <Typography item variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>*/}
        {/*        /!* TODO: Implement actual data from API. *!/*/}
        {/*        {'136'} items dispatched*/}
        {/*      </Typography>*/}
        {/*      <Typography item variant={'subtitle2'} sx={{ fontWeight: 'normal' }}>*/}
        {/*        /!* TODO: Implement actual data from API. *!/*/}
        {/*        Nov 04 2021*/}
        {/*      </Typography>*/}
        {/*    </Stack>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}

        {/* Mobile - Break to clean up the mobile UI a bit. */}
        {isMobile ? (
          <Grid item xs={12}>
            <hr />
          </Grid>
        ) : (
          ''
        )}
      </Grid>

      {/* Ordered Items Container - Mobile View and PC Views for data. */}
      {width < 600 ? (
        <TableContainer>
          <hr />
          <Table aria-label="spanning table">
            <TableHead>
              {/* Ordered Items Headers */}
              <TableRow>
                <TableCell align="left" width={isMobile ? '15%' : '50%'} style={{ fontWeight: `bold` }}>
                  Item
                </TableCell>
                <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                  Qty.
                </TableCell>
                <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                  Unit $
                </TableCell>
                <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                  Total $
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Ordered Items Rows */}
              {saleOrders &&
                saleOrders?.data
                  ?.find(item => item.id === parseInt(page))
                  ?.cart?.cart_items.map(row => (
                    <TableRow key={row.id}>
                      <TableCell align={'left'}>
                        <div>
                          <div style={{ marginBottom: 3 }}>{row?.product_variant ? `${row?.product_variant?.variant_name}` : row?.product?.product_name}</div>
                          <div style={{ marginBottom: 3 }}>{row?.product_variant.variant_code}</div>
                          <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                            {row?.product_variant &&
                              row?.product_variant?.product_attributes.map(attribute => {
                                // eslint-disable-next-line react/jsx-key
                                return <Chip label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                              })}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center">{row.quantity}</TableCell>
                      <TableCell align="center">{row?.item_total}</TableCell>
                      <TableCell align="center">${row.quantity * row?.item_total}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            <TableBody>
              {user?.customer && !user?.customer?.cc_exception ? (
                <TableRow>
                  <TableCell colSpan={width < 600 ? 2 : 2} style={{ padding: 15 }} />
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 10, padding: 15 }}>
                    Processing Fee
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 10, padding: 15 }}>
                    ${cart && cart?.data && cart?.data ? FormatUtil.numberWithCommas(parseFloat(cart?.data?.cart_total) * user?.customer?.cc_rate) : 0}
                  </TableCell>
                </TableRow>
              ) : (
                ''
              )}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={width < 600 ? 2 : 1} />
                <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  Total ({totalItems(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart)} Items)
                </TableCell>
                <TableCell align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  $
                  {saleOrders && saleOrders?.data?.find(item => item.id === parseInt(page))?.cart
                    ? FormatUtil.numberWithCommas(parseFloat(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart?.cart_total))
                    : 0}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {saleOrders &&
                saleOrders?.data &&
                saleOrders?.data
                  ?.find(item => item.id === parseInt(page))
                  ?.sale_order_payments.map(item => (
                    <TableRow>
                      <TableCell colSpan={width < 600 ? 2 : 2} />
                      <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                        <div>{item.journal}</div>
                        {item.payment_date && <div style={{ fontSize: 10 }}>Date: {item.payment_date}</div>}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                        -$
                        {item?.amount}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={width < 600 ? 2 : 2} />
                <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  Amount Due:
                </TableCell>
                <TableCell align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  {processSubTotal(
                    'card',
                    saleOrders && saleOrders?.data?.find(item => item.id === parseInt(page))?.cart ? parseFloat(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart?.cart_total) : 0,
                    user,
                    saleOrders && saleOrders?.data?.find(item => item.id === parseInt(page))?.sale_order_payments,
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter> {/* Just in case we need to add anything in a footer. */}</TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <hr />
          <Table aria-label="spanning table">
            <TableHead>
              {/* Ordered Items Headers */}
              <TableRow>
                <TableCell align="left" width={isMobile ? '10%' : '50%'} style={{ fontWeight: `bold` }}>
                  Item
                </TableCell>
                <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                  Qty.
                </TableCell>
                <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                  Unit $
                </TableCell>
                <TableCell align="center" width={'10%'} style={{ fontWeight: `bold` }}>
                  Total $
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Ordered Items Rows */}
              {saleOrders &&
                saleOrders?.data
                  ?.find(item => item.id === parseInt(page))
                  ?.cart?.cart_items.map(row => (
                    <TableRow key={row.id}>
                      <TableCell align={'left'}>
                        <Grid container>
                          <div>
                            <div style={{ marginBottom: 3 }}>{row?.product_variant ? `${row?.product_variant?.variant_name}` : row?.product?.product_name}</div>
                            <div style={{ marginBottom: 3 }}>{row?.product_variant.variant_code}</div>
                            <div style={{ marginBottom: 5, position: `relative`, left: -5 }}>
                              {row?.product_variant &&
                                row?.product_variant?.product_attributes.map(attribute => {
                                  // eslint-disable-next-line react/jsx-key
                                  return <Chip label={attribute.attribute_value} style={{ marginRight: 5, fontSize: 10 }} size={`small`} />;
                                })}
                            </div>
                          </div>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">{row.quantity}</TableCell>
                      <TableCell align="center">{row?.item_total}</TableCell>
                      <TableCell align="center">${row.quantity * row?.item_total}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            <TableBody>
              {user?.customer && !user?.customer?.cc_exception ? (
                <TableRow>
                  <TableCell colSpan={width < 600 ? 1 : 2} style={{ padding: 15 }} />
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 12, padding: 15 }}>
                    Processing Fee
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: `bold`, fontSize: 12, padding: 15 }}>
                    $
                    {saleOrders && saleOrders?.data?.find(item => item.id === parseInt(page))?.cart
                      ? FormatUtil.numberWithCommas(parseFloat(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart?.cart_total * user?.customer?.cc_rate))
                      : 0}
                  </TableCell>
                </TableRow>
              ) : (
                ''
              )}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={width < 600 ? 1 : 2} />
                <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  Total ({totalItems(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart)} Items)
                </TableCell>
                <TableCell align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  $
                  {user?.customer && !user?.customer?.cc_exception
                    ? saleOrders &&
                      FormatUtil.numberWithCommas(
                        saleOrders?.data?.find(item => item.id === parseInt(page))?.cart
                          ? parseFloat(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart?.cart_total) +
                              parseFloat(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart?.cart_total * user?.customer?.cc_rate)
                          : 0,
                      )
                    : saleOrders?.data?.find(item => item.id === parseInt(page))?.cart
                    ? parseFloat(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart?.cart_total)
                    : 0}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {saleOrders &&
                saleOrders?.data &&
                saleOrders?.data
                  ?.find(item => item.id === parseInt(page))
                  ?.sale_order_payments.map(item => (
                    <TableRow>
                      <TableCell colSpan={width < 600 ? 1 : 2} />
                      <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 12, padding: 15 }}>
                        <div>{item.journal}</div>
                        {item.payment_date && <div style={{ fontSize: 10 }}>Date: {item.payment_date}</div>}
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 12, padding: 15 }}>
                        -$
                        {item?.amount}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell colSpan={width < 600 ? 1 : 2} />
                <TableCell colSpan={width < 600 ? 1 : 1} align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  Amount Due:
                </TableCell>
                <TableCell align="center" style={{ fontWeight: `bold`, fontSize: width < 600 ? 10 : 16, padding: 15 }}>
                  $
                  {processSubTotal(
                    'card',
                    saleOrders && saleOrders?.data?.find(item => item.id === parseInt(page))?.cart ? parseFloat(saleOrders?.data?.find(item => item.id === parseInt(page))?.cart?.cart_total) : 0,
                    user,
                    saleOrders && saleOrders?.data?.find(item => item.id === parseInt(page))?.sale_order_payments,
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter> {/* Just in case we need to add anything in a footer. */}</TableFooter>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

OrdersItemsPage.propTypes = {
  token: PropTypes.string,
  history: PropTypes.object,
  isMobile: PropTypes.bool,
  user: PropTypes.object,
  saleOrders: PropTypes.object,
  saleOrderRead: PropTypes.object,
  saleOrderUpdate: PropTypes.object,
  saleOrderCancel: PropTypes.object,
  match: PropTypes.object,
  cart: PropTypes.object,
  cartUpdate: PropTypes.object,
  doGetSaleOrders: PropTypes.func,
  doCancelSaleOrder: PropTypes.func,
  doResetSaleOrder: PropTypes.func,
  doReadSaleOrder: PropTypes.func,
  doUpdateSaleOrder: PropTypes.func,
  doResetCartUpdate: PropTypes.func,
  doReadCart: PropTypes.func,
  doResetCart: PropTypes.func,
  doUpdateCart: PropTypes.func,
  doDuplicateCart: PropTypes.func,
  width: PropTypes.number,
};

export default OrdersItemsPage;

//IMPORTS
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConnectedRouter } from 'connected-react-router';

//REDUX
import store from '../redux/store';

//CONTAINERS
import AppContainer from '../containers/App';
import Auth from '../containers/Auth';

//UTILS
import NetworkService from '../utils/NetworkService';

import Catalog from '../containers/Catalog';
import PrivateRoute from './private';
import ErrorRoute from './error';

import { createHashHistory, createBrowserHistory } from 'history';

import { Router } from 'react-router-dom';

import Dynamic from '../containers/DynamicPage';
import { Redirect } from 'react-router';
import ShopSelector from '../views/Landing/ShopSelector';
import { doSetSite } from '../containers/Auth/operations';
import { Box, CircularProgress } from '@mui/material';
// Config
// var history;
// Configure History
// export const history = createBrowserHistory();
//if (window?.clientInformation && window?.clientInformation?.platform && window?.clientInformation?.platform === 'iPhone') {
// var history = createHashHistory();
//} else {
var history = createBrowserHistory();
// var history = createHashHistory();
//}

//INTERCEPTORS
NetworkService.setupInterceptors({ store, history });

//MAIN COMPONENT
/**
 * Main route component
 * @param {*} props default component props
 * @returns {JSX.Element} react component
 */
const RoutesContainer = props => {
  // State / Props
  const { site, user, activation, doSetSite } = props;

  const [websiteSelectorId, setWebsiteSelectorId] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const loadScript = selectorId => {
    if (!window.WEBSITE_SELECTOR) {
      return;
    }
    // Identifier for the script element to make it easier to locate and remove
    const scriptId = 'dynamic-env-script';

    // Remove existing script if it exists
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.remove();
    }

    // Create a new script element
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = selectorId === 'Utah' ? `${process.env.PUBLIC_URL}/ut.env.js` : `${process.env.PUBLIC_URL}/co.env.js`;
    document.head.appendChild(script);

    // Optional: Load callback to execute code after the script is loaded
    script.onload = () => {
      // Code to execute after the script is loaded
      console.log(`${selectorId} script loaded`);
    };
  };

  useEffect(() => {
    if (site != null) {
      switch (site) {
        case 'Utah':
          loadScript('Utah');
          window.BASE_URL = 'https://ut.alpinadistribution.com/v1/';
          window.API_URL = 'https://ut.alpinadistribution.com/v1/api';
          break;
        case 'Colorado':
          loadScript('Colorado');
          window.BASE_URL = 'https://co.alpinadistribution.com/v1/';
          window.API_URL = 'https://co.alpinadistribution.com/v1/api';
          break;
      }
    }
    // console.log('LOADED');
    setLoaded(true);
  }, [site]);

  return (
    <Router history={history}>
      <AppContainer>
        {!loaded ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        ) : activation?.data || !user ? (
          <>
            {site == null && window?.WEBSITE_SELECTOR === 1 ? (
              <Switch>
                <Route
                  exact
                  path={`*`}
                  render={routeProps => <ShopSelector {...routeProps} doSetSite={doSetSite} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />}
                />
              </Switch>
            ) : (
              <Switch>
                <Route exact path={`/privacy`} component={Dynamic} />
                <Route path="/auth/:view/:type/:token/:validator" render={routeProps => <Auth {...routeProps} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
                <Route path="/auth/:view/:token/:validator" render={routeProps => <Auth {...routeProps} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
                <Route path="/auth/:view/:email" render={routeProps => <Auth {...routeProps} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
                <Route path="/auth/:view" render={routeProps => <Auth {...routeProps} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />

                <Route path="*" render={routeProps => <Auth {...routeProps} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
                {/*<ErrorRoute path="*" status={404} />*/}
              </Switch>
            )}
          </>
        ) : user && user?.role?.name === 'admin' ? (
          site == null && window.WEBSITE_SELECTOR === 1 ? (
            <Switch>
              <Route exact path={`*`} render={routeProps => <ShopSelector {...routeProps} doSetSite={doSetSite} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
            </Switch>
          ) : (
            <Switch>
              <Route
                exact
                path="/auth/:view"
                render={() => {
                  return user ? <Redirect to="/" /> : <Auth websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />;
                }}
              />
              <PrivateRoute exact path="/catalog" redirect={!user} component={Catalog} />
              <PrivateRoute exact path="/catalog/:view" redirect={!user} component={Catalog} />
              <PrivateRoute exact path="/catalog/:view/:page" redirect={!user} component={Catalog} />
              <PrivateRoute exact path="/catalog/:view/:page/:pageid" redirect={!user} component={Catalog} />
              <PrivateRoute exact path="/catalog/:view/:page/:pagetype/:pageid" redirect={!user} component={Catalog} />
              <Route exact path="*" component={Catalog} />
              {/*<ErrorRoute path="*" status={404} />*/}
            </Switch>
          )
        ) : site == null && window?.WEBSITE_SELECTOR === 1 ? (
          <Switch>
            <Route exact path={`*`} render={routeProps => <ShopSelector {...routeProps} doSetSite={doSetSite} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
          </Switch>
        ) : (
          <Switch>
            {/*<Route exact path="/" component={Catalog} />*/}
            <Route exact path="/auth/:view" render={routeProps => <Auth {...routeProps} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
            <Route path="/auth/:view/:type/:token/:validator" render={routeProps => <Auth {...routeProps} websiteSelectorId={websiteSelectorId} setWebsiteSelectorId={setWebsiteSelectorId} />} />
            <ErrorRoute exact path="/admin" status={403} />
            <ErrorRoute exact path="/admin/:view" status={403} />
            <ErrorRoute exact path="/admin/:view/:page" status={403} />
            <PrivateRoute exact path="/catalog" redirect={!user} component={Catalog} />
            <PrivateRoute exact path="/catalog/:view" redirect={!user} component={Catalog} />
            <PrivateRoute exact path="/catalog/:view/:page" redirect={!user} component={Catalog} />
            <PrivateRoute exact path="/catalog/:view/:page/:pageid" redirect={!user} component={Catalog} />
            <PrivateRoute exact path="/catalog/:view/:page/:pagetype/:pageid" redirect={!user} component={Catalog} />
            <Route exact path="*" component={Catalog} />
            {/*<ErrorRoute path="*" status={404} />*/}
          </Switch>
        )}
      </AppContainer>
    </Router>
  );
};

//REDUX
/**
 * Maps redux state to local component state
 * @param {*} state incoming redux state
 * @return {Object} redux state
 */
// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  site: state.authReducer.site,
  user: state.authReducer.user,
  activation: state.authReducer.activation,
});

/**
 * Maps redux actions to local component state
 * @param {*} dispatch incoming redux dispatch
 * @return {Object} redux actions
 */
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSetSite,
    },
    dispatch,
  );

RoutesContainer.propTypes = {
  site: PropTypes.string,
  user: PropTypes.object,
  activation: PropTypes.object,
  doSetSite: PropTypes.func,
};

//EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(RoutesContainer);

export { history };
